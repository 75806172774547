var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list"},[(_vm.title)?_c('h4',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.title),expression:"title"}],staticClass:"container-title title--h3"}):_vm._e(),_vm._v(" "),(!_vm.staticList)?_c('transition-group',{attrs:{"name":"fade","tag":"div"}},_vm._l((_vm.list),function(item){return _c('BlockArchiveItem',_vm._b({key:item.id,staticClass:"list-item",class:{
        'no-border': item.hideBorder === 'Да',
        'no-top-border': item.masterId,
      },attrs:{"is-inner-banner":_vm.isInnerBanner}},'BlockArchiveItem',item,false))}),1):_c('div',{staticClass:"documents-list"},[(_vm.list && _vm.list.length)?_vm._l((_vm.list),function(item,index){return _c('f-block-animation',{key:item.id,staticClass:"card-container",class:{
          'no-border': item.hideBorder === 'Да',
          'no-top-border': item.masterId,
        },attrs:{"delay":_vm.list.length > 20 ? 0 : index / 10}},[_c('BlockArchiveItem',_vm._b({staticClass:"list-item animate-list-item",attrs:{"is-inner-banner":_vm.isInnerBanner},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var linkedDocuments = ref.linkedDocuments;
return _vm._l((linkedDocuments),function(item){return _c('BlockArchiveItem',_vm._b({key:item.id,staticClass:"inner-document"},'BlockArchiveItem',item,false))})}}],null,true)},'BlockArchiveItem',item,false))],1)}):[_c('BlockText',{attrs:{"text-data":"Список пуст"}})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }