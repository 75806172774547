







































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { sectionCoreProps } from '@/mixins/sectionCore'
import Tabs from '@/components/Tabs'
import CardFaqList from '@/components/CardLists/CardFaqList.vue'
import BlockListArchiveItems from '@/components/BlockListArchiveItems.vue'
import CardDocumentList from '@/components/CardLists/CardDocumentList.vue'
import SectionBasic from '@/components/SectionBasic.vue'
import BlockBreadCrumbs from '@/components/BlockBreadCrumbs.vue'
import FaqTabs from '@/components/Ssr/FaqTabs.vue'

export default defineComponent({
  name: 'SectionFaq',
  components: {
    FaqTabs,
    Tabs,
    BlockListArchiveItems,
    CardDocumentList,
    CardFaqList,
    SectionBasic,
    BlockBreadCrumbs,
  },
  mixins: [sectionCoreProps],
  props: {
    list: {
      type: Array as PropType<ReadonlyArray<any>>,
      default: () => [],
    },
  },
})
