export interface IPublishInfo {
  readonly month: string
  readonly year: string
  readonly date?: string
}

export const getEmptyPublishInfo = (): IPublishInfo => {
  return {
    month: '0',
    year: '0',
    date: '0',
  }
}
