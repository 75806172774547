




















































import { defineComponent } from '@vue/composition-api'
import { defineProps } from '@/helpers/defineProps'
import { BlockListArchiveItemsProps } from '@/contracts/BlockListArchiveItems'
import BlockArchiveItem from '@/components/BlockArchiveItem.vue'
import BlockText from '@/components/BlockText.vue'

export default defineComponent({
  name: 'BlockListArchiveItems',
  components: { BlockText, BlockArchiveItem },
  props: defineProps<BlockListArchiveItemsProps>({
    title: {
      default: '',
    },
    list: {
      default: () => [],
    },
    isInnerBanner: {
      default: false,
    },
    staticList: {
      default: true,
    },
  }),
})
