



























































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import BlockText from '@/components/BlockText.vue'

export default defineComponent({
  components: { BlockText },
  props: {
    withTabs: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array as PropType<ReadonlyArray<any>>,
      default: () => [],
    },
  },
})
