


















































import { defineComponent } from '@nuxtjs/composition-api'
import { getIconNameByFileType } from '../composition/fileTypeIcon'
import { IDocumentItem } from '@/contracts/DocumentItem'
import { defineProps } from '@/helpers/defineProps'
import { getEmptyPublishInfo } from '@/contracts/IPublishInfo'
import { parseMonth } from '@/composition/parseMonth'

export default defineComponent({
  name: 'BlockArchiveItem',
  props: defineProps<IDocumentItem>({
    title: {
      default: '',
    },
    id: {
      default: '',
    },
    description: {
      default: '',
    },
    showDate: {
      default: '',
    },
    revisionDate: {
      default: '',
    },
    regNumber: {
      default: '',
    },
    dateText: {
      default: '',
    },
    url: {
      default: '',
    },
    pdfWeight: {
      default: '',
    },
    isInnerBanner: {
      default: false,
    },
    contentType: {
      default: '',
    },
    published: {
      default: () => getEmptyPublishInfo(),
    },
    linkedDocuments: {
      default: null,
    },
  }),
  setup(props) {
    const compIconType = getIconNameByFileType(props.contentType || '')
    return {
      parseMonth,
      compIconType,
    }
  },
})
